.admin-menu {
    display: grid;
    flex: 1;
    flex-direction: row;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.admin-menu div {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    margin: 2rem;
}

.menu-item {
    background-color: var(--primary-color);
    padding: 1rem;
    margin: 1rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 9rem;
}

.menu-item:hover {
    cursor: pointer;
    background-color: var(--highlight-color);
}
