.edit-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
}

.edit-item .buttons {
    margin-bottom: 2rem;

    display: grid;
    flex: 1;
    flex-direction: row;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.edit-item .buttons button {
    margin-left: 2rem;
    margin-right: 2rem;
}
