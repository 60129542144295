.users-list {
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 2rem;
    background-color: var(--background-highlight-color);
}

.user {
    padding: 1rem;
    background-color: var(--primary-color);
    margin: 1rem;
}

.user:hover {
    background-color: var(--highlight-color);
    cursor: pointer;
}
