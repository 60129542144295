.mainLogo {
    display: flex;
    position: absolute;
    justify-content: center;
    top: -1rem;
    left: -1rem;
    background-color: var(--primary-color);
    padding: 1.5rem;
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    z-index: 2;
    margin: 0;
}

.mainLogo img {
    max-width: 5rem;
    max-height: 5rem;
}

.top-section button {
    padding: 0.4rem;
    margin: 0;
    height: 3rem;
    font-size: 0.8rem;
    border-left: 1rem solid var(--primary-color);
    display: flex;
    align-items: center;
}

.top-section button:hover {
    background-color: var(--highlight-color);
}

.top-section {
    font-size: 1rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    background: var(--primary-color);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    align-items: center;
    min-height: 3rem;
}

input {
    border-radius: 0;
    border: none;
    text-align: center;
    font-size: 1.8rem;
    color: white;
    padding: 1rem;
    flex: 1;
    background-color: var(--primary-color);
}

h1 {
    font-size: 5rem;
}

input:focus {
    border-color: var(--primary-color);
    outline: none;
}

.input-label {
    text-align: center;
    font-weight: bold;
}

select {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 1rem;
    font-size: 1.5rem;
}

select:disabled {
    background-color: var(--disabled-color);
    color: white;
}

button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 1rem;
    font-size: 2rem;
    outline: none;
    border-radius: 1rem;
}

button:disabled:hover {
    cursor: not-allowed;
    background-color: var(--disabled-color);
}

button:disabled,
button:disabled:active {
    color: white;
    background-color: var(--disabled-color);
}

.danger-button {
    background-color: red;
}

.danger-button:hover {
    background-color: rgba(211, 0, 0, 0.781);
}

button:hover {
    cursor: pointer;
    background-color: var(--highlight-color);
}

button:active {
    background-color: var(--highlighter-color);
}

.selectable:hover {
    background-color: var(--primary-color);
    cursor: pointer;
    transition: background-color 0.2s;
}

.selectable:active {
    background-color: var(--highlight-color);
    cursor: pointer;
}

.selectable.disabled {
    background-color: var(--disabled-color);
    color: var(--background-color);
}

.selectable.disabled:hover {
    background-color: var(--disabled-color);
    color: var(--background-color);
    cursor: not-allowed;
}

.add-button {
    align-self: center;
}

.page-content {
    padding-top: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding-left: 1rem;
    padding-right: 1rem;
}

.flex-grid-300 {
    display: grid;
    flex: 1;
    flex-direction: row;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.flex-col {
    display: flex;
    flex-direction: column;
}

input {
    margin: 0.2rem 2rem 2rem 2rem;
    flex: 1 1 auto;
    display: flex;
}
