.edit-list .selected-items {
    background-color: var(--background-highlight-color);
    padding: 1rem;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 3rem;
    margin-top: 1rem;
}

.edit-list .item {
    display: inline-block;
    padding: 0.4rem;
    border-radius: 1rem;
    background-color: var(--primary-color);
    color: white;
}

.edit-list .item:hover {
    background-color: var(--highlight-color);
}

.edit-list .item-contents {
    display: flex;
    align-items: center;
}

.edit-list .item-name {
    margin: 0.3rem;
}

.edit-list .delete-item:hover {
    cursor: pointer;
    background-color: transparent;
}

.edit-list .delete-item {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
    background-color: transparent;
}

.edit-list {
    display: flex;
    align-items: stretch;
    flex-direction: column;
}
